.support {
  @media only screen and (min-width: 37.5625rem) {


    &-icon {
      max-height: 37.5rem;
      color: #fff;
      margin: 0.5rem 0;
    }
  }


  &-button {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 3.125rem;
    height: auto;
    cursor: pointer;
    z-index: 1000;
    top: 12.5rem;


    right: 0;

    &>button {
      margin: 0.5rem 0;
      color: #ffffff;
    }
  }

  &-content {
    position: relative;
    padding: 1.0rem;
    background-color: #fff;
    color: #000;
  }

  &-popper {
    border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: -3px 7px 14px 5px rgba(0, 0, 0, 0.35);
    padding: 1rem;
    background-color: #fff;
    position: relative;
    max-width: 20rem;
    max-height: 25rem;
    overflow-y: auto;
    margin-right: -0.3rem;

    &-close-button {
      position: absolute;
      z-index: 3000;
      top: 1em;
      right: 1em;
      cursor: pointer;
      background: none;
      border: none;
    }
  }

  &-icon {
    width: 1.625rem;
    height: auto !important;
    color: #fff;
  }
}