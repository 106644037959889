#auth-options-container {
  display: flex;
  margin: auto;
  margin-top: 2rem;
  width: 20rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
}

#bund-id {
  width: 20%;
}

.box-style {
  display: flex;
  width: 20rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    height: 6rem;
    margin-bottom: 1.25rem;
  }
}

@media (min-width: 1080px) {
  #auth-options-container {
    margin-top: 5rem;
    transform: scale(1.2);
  }
}
