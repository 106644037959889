body,
html {
  padding: 0 !important;
  margin: 0;
  height: 100%;
}

// is needed to have fixed footer
.app-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

html {
  overflow-y: scroll !important;
  max-width: 120rem;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.custom-cookies-link {
  padding-left: 1.5rem;
}