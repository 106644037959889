.dialog { 
  padding: 0%;
  margin: 0%;
}

#modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#modal {
  width: 41rem;
  background-color: #ffffff;

  h3, p {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    font-family: "IBM Plex Sans";
  }

  h3 {
    margin-top: 2.5rem;
    line-height: 30px;
    font-size: 25px;
  }
  
  p {
    margin-top: 1.25rem;
    line-height: 26px;
    font-size: 17px;
  }
}

#modal-mobile {
  width: 19rem;
  background-color: #ffffff;
  position: relative;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
  font-family: "IBM Plex Sans";
  font-size: 14px;

  h3, p {
    margin-top: 1.25rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    font-family: "IBM Plex Sans";
  }

  h3 {
    line-height: 30px;
    font-size: 25px;
  }
  
  p {
    line-height: 26px;
    font-size: 17px;
  }
}

#modal-header{
  background-color: #0074be;
  height: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 4rem;
  }
}

#modal-header-mobile{
  background-color: #0074be;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 3rem;
  }
}

#modal-button-container {
  display: flex;
  align-items:center;
}

.button-style {
  background-color: #0074be;
  color: #ffffff;
  height: 2.5rem;
  font-size: 1rem;
  border: none;
  border-radius: 2rem;
  font-family: "IBM Plex Sans";
  letter-spacing: 8%;
  margin-left: auto;
  margin-right: auto;
}

#modal-button {
  width: 27.5rem;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

#modal-button-mobile {
  width: 12.5rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
