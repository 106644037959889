@import "../../../../../styles/variables";

.certificates-list {
  &-itemsHeader {
    border-bottom: 0.06rem solid $grey;
    padding-bottom: 0.62rem;
    margin-top: 2rem;
  }

  &-item {
    padding: 0.62rem 0 0.62rem 0;
    border-bottom: 0.06rem solid $grey;
    background-color: #f8f8f8;
    
    &-col {
      margin: auto !important;
      color: #000;
      fill:#000;
    }

    &-delButton {
      cursor: pointer;
      color: $red;
    }

    &-delButton-mono {
      cursor: pointer;
      color: #000;
    }

    &-delButtonDisabled {
      cursor: pointer;
      color: $grey;
    }

    &-before {
      text-align: center;
      border-right: 0.06rem solid $grey;
    }

    &-after {
      text-align: center;
    }
  }

  &-item:hover {
    background-color: $secondary-accent-2;
  }

  &-downloadIcon {
    fill: aqua;
    margin-right: 0.62rem;
  }

  &-downloadIcon:hover {
    fill: $grey !important;
  }
}

.download-area {
  direction: "row";
  justify-content: "flex-end";
  align-items: "center" ;
}
